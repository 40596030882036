import { Navigate, useLocation } from "react-router-dom";

import {
  ApiReportReportStatusChoices,
  ApiReportSectionsAiGeneratedStatusChoices,
  ApiReportTreatmentPlanAiLtgPredictionStatusChoices,
  ApiReportTreatmentPlanAiStgPredictionStatusChoices,
  ApiReportTreatmentPlanAiTargetPredictionStatusChoices,
  ReportSectionsType,
  ReportType,
} from "@api/graphql/types-and-hooks";
import { StatusType } from "@components/AssessmentReportsTable/AssessmentReportsTableV2";
import { InitialAssessmentPageRoute } from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import { useAssessmentBuilderData as useAssessmentBuilderV2Data } from "@providers/AssessmentBuilderV2Provider.tsx";

// TODO: BACKPORT: Will need to figure out a generic method for computing pages depending on report type
// NOTE: This is part of a much bigger pattern that needs to get figured out on the UI side
// which is how to handle dynamic routing based on report types
export const AssessmentReportV2DetailsRedirect = () => {
  const { state } = useLocation();
  const { assessmentQuery } = useAssessmentBuilderV2Data();
  const assessment = assessmentQuery.data?.getReports?.edges[0]?.node as ReportType;

  const pathToRedirectTo = getStatusAndRedirectRouteFromReport(assessment).redirectRoute;

  return <Navigate to={pathToRedirectTo} replace state={state} />;
};

export const getStatusAndRedirectRouteFromReport = (
  report?: ReportType,
): { status: StatusType; redirectRoute: InitialAssessmentPageRoute } => {
  const getFilteredSections = (location: string): ReportSectionsType[] =>
    report?.reportSections?.edges
      ?.map((edge) => edge?.node)
      .filter((section): section is ReportSectionsType => !!section && section.pageLocation === location) ?? [];

  const isSectionErrored = (sections: ReportSectionsType[]): boolean =>
    sections.some((section) => section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Failure);

  const isSectionGenerated = (sections: ReportSectionsType[]): boolean =>
    sections.some(
      (section) =>
        section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted ||
        section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Pending,
    );

  const isSectionPending = (sections: ReportSectionsType[]): boolean =>
    sections.length === 0 ||
    sections.every((section) => section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Pending);

  const isSectionGenerating = (sections: ReportSectionsType[]): boolean =>
    sections.some(
      (section) => section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
    );

  const reportFiles = report?.reportFiles?.edges?.map((edge) => edge?.node) ?? [];
  const page1Sections = getFilteredSections("report-part-1");
  const page2Sections = getFilteredSections("report-part-2");

  const ltgGenerating =
    report?.reportTreatmentPlan?.aiLtgPredictionStatus ===
    ApiReportTreatmentPlanAiLtgPredictionStatusChoices.GoalPredictionPending;
  const ltgErrored =
    report?.reportTreatmentPlan?.aiLtgPredictionStatus === ApiReportTreatmentPlanAiLtgPredictionStatusChoices.Failure;
  const ltgGenerated =
    report?.reportTreatmentPlan?.aiLtgPredictionStatus ===
    ApiReportTreatmentPlanAiLtgPredictionStatusChoices.GoalPredictionPredicted;

  const stgGenerating =
    report?.reportTreatmentPlan?.aiStgPredictionStatus ===
    ApiReportTreatmentPlanAiStgPredictionStatusChoices.GoalPredictionPending;
  const stgErrored =
    report?.reportTreatmentPlan?.aiStgPredictionStatus === ApiReportTreatmentPlanAiStgPredictionStatusChoices.Failure;
  const stgGenerated =
    report?.reportTreatmentPlan?.aiStgPredictionStatus ===
    ApiReportTreatmentPlanAiStgPredictionStatusChoices.GoalPredictionPredicted;

  const targetsGenerating =
    report?.reportTreatmentPlan?.aiTargetPredictionStatus ===
    ApiReportTreatmentPlanAiTargetPredictionStatusChoices.GoalPredictionPending;
  const targetsErrored =
    report?.reportTreatmentPlan?.aiTargetPredictionStatus ===
    ApiReportTreatmentPlanAiTargetPredictionStatusChoices.Failure;
  const targetsGenerated =
    report?.reportTreatmentPlan?.aiTargetPredictionStatus ===
    ApiReportTreatmentPlanAiTargetPredictionStatusChoices.GoalPredictionPredicted;

  if (report?.reportStatus === ApiReportReportStatusChoices.Completed) {
    return { status: "Completed", redirectRoute: "review-and-sign" };
  }

  if (isSectionErrored(page2Sections)) {
    return { status: "Error on Report Page 2", redirectRoute: "report-part-2" };
  }

  if (isSectionGenerating(page2Sections)) {
    return { status: "Generating Report Page 2", redirectRoute: "report-part-2" };
  }

  if (isSectionGenerated(page2Sections)) {
    return { status: "At Report Page 2", redirectRoute: "report-part-2" };
  }

  if (targetsErrored) {
    return { status: "Error Generating Targets", redirectRoute: "short-term-goals" };
  }

  if (targetsGenerating) {
    return { status: "Generating Targets", redirectRoute: "short-term-goals" };
  }

  if (stgErrored) {
    return { status: "Error Generating Short Term Goals", redirectRoute: "short-term-goals" };
  }

  if (stgGenerating) {
    return { status: "Generating Short Term Goals", redirectRoute: "short-term-goals" };
  }

  if (stgGenerated && targetsGenerated) {
    return { status: "At Short Term Goals", redirectRoute: "short-term-goals" };
  }

  if (ltgErrored) {
    return { status: "Error Generating Long Term Goals", redirectRoute: "long-term-goals" };
  }

  if (ltgGenerating) {
    return { status: "Generating Long Term Goals", redirectRoute: "long-term-goals" };
  }

  if (ltgGenerated) {
    return { status: "At Long Term Goals", redirectRoute: "long-term-goals" };
  }

  if (isSectionErrored(page1Sections)) {
    return { status: "Error on Report Page 1", redirectRoute: "report-part-1" };
  }

  if (isSectionGenerating(page1Sections)) {
    return { status: "Generating Report Page 1", redirectRoute: "report-part-1" };
  }

  if (isSectionGenerated(page1Sections) || page1Sections.length > 0) {
    return { status: "At Report Page 1", redirectRoute: "report-part-1" };
  }

  if (!report?.reportClientDetails) {
    return { status: "Client Details Needed", redirectRoute: "client-details" };
  }

  if (reportFiles.length === 0) {
    return { status: "Clinical Files Needed", redirectRoute: "upload-files" };
  }
  if (reportFiles.length > 0 && isSectionPending(page1Sections)) {
    return { status: "At Upload Files", redirectRoute: "upload-files" };
  }

  return { status: "Unknown Status", redirectRoute: "upload-files" };
};
