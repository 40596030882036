import { datadogRum } from "@datadog/browser-rum";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { APP_ENV, cookieDomain, tracingUrl } from "@utils/utils";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FronteraAuthProvider } from "@fronterahealth/frontera-auth0-react";

import "@components/notifications/notifications.css";
import { AdminDataProvider } from "@providers/AdminDataProvider";
import { FeatureFlagProvider } from "@providers/FeatureFlagProvider";
import { InternationalizationProvider } from "@providers/InternationalizationProvider";
import { UIStateProvider } from "@providers/UIStateProvider";

import "./index.css";
import { router } from "./router";

const queryClient = new QueryClient();

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: "us3.datadoghq.com",
  service: "frontera-portal-ui",
  env: APP_ENV,
  version: import.meta.env.VITE_COMMIT_HASH || "<missing-version>",
  sessionSampleRate: 100,
  sessionReplaySampleRate: APP_ENV === "prod" ? 100 : 0,
  allowedTracingUrls: tracingUrl ? [tracingUrl] : undefined,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <FronteraAuthProvider
      loginOnMount={true}
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      audience={import.meta.env.VITE_AUTH0_AUDIENCE}
      cookieDomain={cookieDomain}
    >
      <InternationalizationProvider>
        <QueryClientProvider client={queryClient}>
          <AdminDataProvider>
            <ToastContainer pauseOnFocusLoss={false} autoClose={2000} hideProgressBar />
            <FeatureFlagProvider>
              <UIStateProvider>
                <RouterProvider router={router} />
              </UIStateProvider>
            </FeatureFlagProvider>
          </AdminDataProvider>
        </QueryClientProvider>
      </InternationalizationProvider>
    </FronteraAuthProvider>
  </React.StrictMode>,
);
