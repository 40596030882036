import { SparklesIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Heading, Small } from "@fronterahealth/frontera-ui-components";

import {
  InitialAssessmentPageRoute,
  useOrderedAssessmentReportSubRoutes,
} from "@pages/AssessmentReportV2Details/AssessmentReportDetails";

interface ReportNotReadyYetProps {
  item: "Prioritization" | "Long Term Goals" | "Short Term Goals" | "Report Part 2" | "Report Part 1" | "Review";
}

const useGetPreviousRoute = () => {
  const { pathname } = useLocation();
  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as InitialAssessmentPageRoute) ||
    ("upload-files" as InitialAssessmentPageRoute);
  const { getPastRoute } = useOrderedAssessmentReportSubRoutes();

  const pastRoute = getPastRoute(currentRoute);
  return pastRoute;
};

export const ReportNotReadyYet: React.FC<ReportNotReadyYetProps> = ({ item }) => {
  const go = useNavigate();
  const pastRoute = useGetPreviousRoute();
  return (
    <div className="mt-10 flex flex-col items-center justify-center gap-y-4">
      <SparklesIcon className="h-12 w-12 text-limestone-200" /> <Heading type="h4">{item} Not Ready Yet</Heading>
      <Small>
        This step of the report is not ready to be worked on yet. You must fill out and submit the previous steps of the
        report before filling out this one.
      </Small>
      <div className="flex items-center">
        {pastRoute ? (
          <Button
            appearance="link"
            text="Go to previous step"
            onClick={() => {
              go(`../${pastRoute}`);
            }}
          />
        ) : null}
        <Button
          appearance="primary"
          text="Return to Assessments"
          onClick={() => {
            go("/assessment-reports");
          }}
        />
      </div>
    </div>
  );
};
