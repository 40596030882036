import { TrainStopProgressStep } from "@fronterahealth/frontera-ui-components";

import { ReportType } from "@api/graphql/types-and-hooks";
import {
  InitialAssessmentPageRoute,
  orderedAssessmentSubRoutes,
} from "@pages/AssessmentReportV2Details/AssessmentReportDetails";

export const assessmentTrainTopStatusFromCurrentRoute = (
  currentRoute: InitialAssessmentPageRoute,
  navStepRoute: InitialAssessmentPageRoute,
  assessment: ReportType,
  existingAssessmentsFound: boolean,
): TrainStopProgressStep["status"] => {
  const indexOfCurrentRoute = orderedAssessmentSubRoutes.indexOf(currentRoute);
  const indexOfNavStepRoute = orderedAssessmentSubRoutes.indexOf(navStepRoute);

  // If the route matches the current, always mark status as current
  if (currentRoute === navStepRoute) return "current";
  // if the route is before our current one, it must be complete since it's before
  if (indexOfNavStepRoute < indexOfCurrentRoute) return "complete";

  switch (navStepRoute) {
    case "client-details":
      return "complete";

    case "upload-files":
      if (existingAssessmentsFound) {
        return "complete";
      }
      if (assessment.reportClientDetails?.clientFirstName) {
        return "reached";
      }
      return "upcoming";

    case "report-part-1":
    case "prioritize":
    case "long-term-goals":
      if (
        assessment.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "recommendations").length > 0
      ) {
        return "complete";
      }
      if (
        assessment.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "conclusions").length === 0
      ) {
        return "upcoming";
      }
      if (
        assessment.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "recommendations").length === 0
      ) {
        return "reached";
      }
      return "complete";

    case "short-term-goals":
      if (
        assessment.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "recommendations").length > 0
      ) {
        return "complete";
      }
      if (
        assessment.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "conclusions").length === 0
      ) {
        return "upcoming";
      }
      if (
        assessment.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "recommendations").length === 0
      ) {
        return "reached";
      }
      return "complete";

    case "report-part-2":
    case "review-and-sign":
      if (
        assessment.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "recommendations").length > 0
      ) {
        return "reached";
      }
      return "upcoming";
  }
};
